
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import { dealsStatuses } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    TmModal,
    TmFormLine,
    TmButton,
  },
  setup() {
    const title = ref()

    const assignTo = ref(assigneeOptions[3])
    const dealOwner = ref(assigneeOptions[5])

    const priorityOptions = ref(priorityVariant)
    const priorityValue = ref(priorityOptions.value[0])

    const organizationOptions = [
      'Mitsubishi',
      'IBM',
      'Apple',
      'eBay',
    ]
    const organization = ref(organizationOptions[0])

    const stage = ref(dealsStatuses[0])

    const closingDate = ref()

    const amount = ref()

    const pipelineOptions = [
      {
        id: 'us',
        name: 'Sales US',
      },
      {
        id: 'uk',
        name: 'Sales UK',
      },
    ]
    const pipeline = ref(pipelineOptions[0])

    return {
      pipeline,
      pipelineOptions,
      closingDate,
      dealOwner,
      organizationOptions,
      amount,
      organization,
      assignTo,
      dealsStatuses,
      priorityOptions,
      priorityValue,
      assigneeOptions,
      stage,
      title,
    }
  },
})
